import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'fl-empty-box',
  templateUrl: './empty-box.component.html',
  styleUrls: ['./empty-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class EmptyBoxComponent {
  @Input() title?: string;
  @Input() content?: string;
  constructor() {}
}
